import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Button, Popconfirm, Select, Input, Col, Icon } from 'antd';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-sql';
import 'prismjs/themes/prism.css';
import s from './Analytics.module.scss';
import FullPageLoading from '../../component/FullPageLoading';

import {
  saveAnalyticsQuery,
  setQueryTitle,
  setQueryCode,
  setIsQueryCodeChanged,
  setQueryIsCustom,
  updateAnalyticsQuery,
  setSelectedQueryHub,
  setQueryTableName,
  setQueryDb,
  setTableColumns,
  fetchReportTables,
  updateAssociatedChart,
  saveAssociatedChart,
  setAssociatedReportId,
  setAssociatedChartType,
  setAssociatedChartTag,
  setAssociatedChartQuery,
  setAssociatedChartIdentifier,
  setIndexCode,
  setRelatedReportId,
  setChangeMessage,
  setQueryFrequency,
} from '../../actions/analyticsQuery';
import { popupAlert } from '../../actions/alert';
import { isAnalyticsColumnsInvalid } from '../../utils/helpers';

const { Option } = Select;
const { TextArea } = Input;

const placeholder = `[ WITH [ RECURSIVE ] with_query [, ...] ]
  SELECT [ ALL | DISTINCT [ ON ( expression [, ...] ) ] ]
  [ FROM from_item [, ...] ]
  [ WHERE condition ]
  [ GROUP BY grouping_element [, ...] ]
  [ HAVING condition [, ...] ]
`;

const indexPlaceholder = `CREATE [ UNIQUE ] INDEX [ CONCURRENTLY ] [ name ] ON table [ USING method ]
    ( { column | ( expression ) } [ COLLATE collation ] [ opclass ] [ ASC | DESC ] [ NULLS { FIRST | LAST } ] [, ...] )
    [ WITH ( storage_parameter = value [, ... ] ) ]
    [ TABLESPACE tablespace ]
    [ WHERE predicate ]
`;

class AnalyticsQueryForm extends React.Component {
  componentDidMount() {
    const { type, fetchReportTables } = this.props;
    if (type === 'associate-charts') {
      fetchReportTables();
    }
  }

  dataTypeOptions = [
    {
      name: 'Text',
      value: 'text',
    },
    {
      name: 'Integer',
      value: 'integer',
    },
    {
      name: 'Big Integer',
      value: 'bigint',
    },
    {
      name: 'Boolean',
      value: 'boolean',
    },
    {
      name: 'Json',
      value: 'jsonb',
    },
    {
      name: 'Date',
      value: 'date',
    },
    {
      name: 'Double',
      value: 'double precision',
    },
    {
      name: 'DateTime',
      value: 'timestamp',
    },
  ];

  handleChange = key => code => {
    const { setQueryCode, setIsQueryCodeChanged, setIndexCode } = this.props;
    let isChanged = false;
    if (code.length > 0) {
      isChanged = true;
    }
    if (key === 'sql') {
      setQueryCode(code);
      setIsQueryCodeChanged(isChanged);
    }
    if (key === 'index') {
      setIndexCode(code);
    }
  };

  handleDiscard = () => {
    const { setQueryCode, setIsQueryCodeChanged } = this.props;
    setQueryCode('');
    setIsQueryCodeChanged(false);
  };

  // handleSwitchChange = val => {
  //   const { setQueryIsCustom } = this.props;
  //   setQueryIsCustom(val);
  // };

  // handleHubSelection = val => {
  //   const { setSelectedQueryHub } = this.props;
  //   setSelectedQueryHub(val);
  // };

  // renderOptions = hubs => {
  //   return sortBy(hubs, 'id').map(hub => (
  //     <Option value={hub.id} key={hub.id}>
  //       {hub.id} - {hub.name}
  //     </Option>
  //   ));
  // };

  handleSave = () => {
    const {
      saveAnalyticsQuery,
      popupAlert,
      queryTitle,
      queryCode,
      queryIsCustom,
      match,
      updateAnalyticsQuery,
      selectedHub,
      queryTableName,
      tableColumns,
      indexCode,
      queryDb,
      frequency,
    } = this.props;
    if (!queryCode || !queryTitle || !indexCode) {
      popupAlert({ type: 'error', message: 'Fill in all require fields' });
      return;
    }
    const objToSave = {
      title: queryTitle,
      query: queryCode,
      isCustom: queryIsCustom,
      index: indexCode,
      frequency,
    };
    if (queryIsCustom) {
      objToSave.hub = selectedHub;
    }
    if (queryTableName) {
      objToSave.tableName = queryTableName;
    }
    if (queryDb) {
      objToSave.queryDb = queryDb;
    } else {
      objToSave.queryDb = 'primary_db';
    }
    if (match.params && match.params.id) {
      objToSave.id = match.params.id;
      if (isAnalyticsColumnsInvalid(tableColumns)) {
        popupAlert({
          type: 'error',
          message: 'Datatype required for all columns',
        });
        return;
      }
      objToSave.columns = tableColumns;
      updateAnalyticsQuery(objToSave);
    } else {
      saveAnalyticsQuery(objToSave);
    }
  };

  handleAssociationSave = () => {
    const {
      reportId,
      chartType,
      chartQuery,
      chartTag,
      chartIdentifier,
      updateAssociatedChart,
      saveAssociatedChart,
      match,
      relatedReportId,
      changeMessage,
    } = this.props;
    if (!reportId || !chartType || !chartTag || !chartIdentifier) {
      popupAlert({ type: 'error', message: 'Fill in all require fields' });
      return;
    }
    const objToSave = {
      reportId,
      chartType,
      chartQuery,
      chartTag,
      chartIdentifier,
    };
    if (relatedReportId) {
      objToSave.relatedReportId = relatedReportId;
    }
    if (changeMessage) {
      objToSave.changeMessage = changeMessage;
    }
    if (match.params && match.params.id) {
      objToSave.id = match.params.id;
      updateAssociatedChart(objToSave);
    } else {
      saveAssociatedChart(objToSave);
    }
  };

  handleTitleChange = e => {
    const { setQueryTitle } = this.props;
    setQueryTitle(e.target.value);
  };

  handleTableNameChange = e => {
    const { setQueryTableName } = this.props;
    setQueryTableName(e.target.value);
  };

  handleFreqChange = val => {
    const { setQueryFrequency } = this.props;
    setQueryFrequency(val);
  };

  handleDbChange = val => {
    const { setQueryDb } = this.props;
    setQueryDb(val);
  };

  handleColumnChange = (index, key) => e => {
    const { tableColumns, setTableColumns } = this.props;
    const modifiedColumns = tableColumns.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          [key]: key === 'alias' ? e.target.value : e,
        };
      }
      return item;
    });
    setTableColumns(modifiedColumns);
  };

  handleSelectChange = key => val => {
    const {
      setAssociatedReportId,
      setAssociatedChartType,
      setAssociatedChartTag,
    } = this.props;
    if (key === 'reportId') {
      setAssociatedReportId(val);
    } else if (key === 'chartType') {
      setAssociatedChartType(val);
    } else if (key === 'chartTag') {
      setAssociatedChartTag(val);
    }
  };

  handleChartQuery = val => {
    const { setAssociatedChartQuery } = this.props;
    setAssociatedChartQuery(val);
  };

  handleChartIdentifierChange = e => {
    const { setAssociatedChartIdentifier } = this.props;
    setAssociatedChartIdentifier(e.target.value);
  };

  handleReportRelatedIdChange = e => {
    const { setRelatedReportId } = this.props;
    setRelatedReportId(e.target.value);
  };

  handleChangeMessage = e => {
    const { setChangeMessage } = this.props;
    setChangeMessage(e.target.value);
  };

  renderForm = () => {
    const {
      type,
      queryTitle,
      queryCode,
      queryTableName,
      reportTable,
      reportId,
      chartType,
      chartQuery,
      chartTag,
      chartIdentifier,
      indexCode,
      relatedReportId,
      changeMessage,
      queryDb,
      frequency,
    } = this.props;
    let formElements = [];
    if (type === 'build-analytics-chart') {
      formElements = [
        {
          title: 'Query title',
          placeholder: 'Title for internal tracking',
          value: queryTitle,
          handler: this.handleTitleChange,
          type: 'input',
        },
        {
          title: 'SQL Query',
          placeholder: placeholder,
          value: queryCode,
          handler: this.handleChange('sql'),
          type: 'editor',
        },
        {
          title: 'Database',
          placeholder: 'Select Database',
          value: queryDb,
          handler: this.handleDbChange,
          type: 'select',
          options: [
            {
              key: 'primary_db',
              label: 'Primary DB',
            },
            {
              key: 'analytics_db',
              label: 'Analytics DB',
            },
            {
              key: 'jobs_service_db',
              label: 'Jobs Service DB',
            },
          ],
        },
        {
          title: 'Table Name (Optional)',
          placeholder: 'Table name for feeder query',
          value: queryTableName,
          handler: this.handleTableNameChange,
          type: 'input',
        },
        {
          title: 'Frequency',
          value: frequency,
          handler: this.handleFreqChange,
          type: 'select',
          options: [
            {
              key: 'daily',
              label: 'Daily',
            },
            {
              key: 'weekly',
              label: 'Weekly (Sunday)',
            },
            {
              key: 'monthly',
              label: 'Monthly',
            },
          ],
        },
        {
          title: 'Index Query',
          placeholder: indexPlaceholder,
          value: indexCode,
          handler: this.handleChange('index'),
          type: 'editor',
        },
      ];
    }
    if (type === 'associate-charts') {
      formElements = [
        {
          title: 'Report Id',
          placeholder: 'Select Report Table',
          value: reportId,
          handler: this.handleSelectChange('reportId'),
          type: 'select',
          options: reportTable,
        },
        {
          title: 'Chart Type',
          placeholder: 'Select Chart Type',
          value: chartType,
          handler: this.handleSelectChange('chartType'),
          type: 'select',
          options: [
            {
              key: 'number_overlay',
              label: 'Number Overlay',
            },
            {
              key: 'stacked_bar',
              label: 'Stacked Bar',
            },
            {
              key: 'percentage',
              label: 'Percentage',
            },
            {
              key: 'percentage_stacked_bar',
              label: 'Percentage Stacked Bar',
            },
            {
              key: 'map',
              label: 'Map',
            },
            {
              key: 'feedback_rating',
              label: 'Feedback Rating',
            },
          ],
        },
        {
          title: 'Chart Query (Optional)',
          placeholder: placeholder,
          value: chartQuery,
          handler: this.handleChartQuery,
          type: 'editor',
        },
        {
          title: 'Chart Tag',
          placeholder: 'Select Chart Tag',
          value: chartTag,
          handler: this.handleSelectChange('chartTag'),
          type: 'select',
          options: [
            {
              key: 'activity',
              label: 'Activity',
            },
            {
              key: 'connection',
              label: 'Connection',
            },
            {
              key: 'feedback',
              label: 'Feedback',
            },
            {
              key: 'message',
              label: 'Message',
            },
            {
              key: 'usage',
              label: 'Usage',
            },
            {
              key: 'user',
              label: 'User',
            },
            {
              key: 'job_application',
              label: 'Job Application',
            },
            {
              key: 'job_post',
              label: 'Job Posts',
            },
          ],
        },
        {
          title: 'Chart Identifier',
          placeholder: 'Enter Chart Identifier',
          value: chartIdentifier,
          handler: this.handleChartIdentifierChange,
          type: 'input',
        },
        {
          title: 'Related Report Id',
          placeholder: 'Enter Related Report Id',
          value: relatedReportId,
          handler: this.handleReportRelatedIdChange,
          type: 'input',
        },
        {
          title: 'Change Message',
          placeholder: 'Enter Change Message If Any',
          value: changeMessage,
          handler: this.handleChangeMessage,
          type: 'textarea',
        },
      ];
    }
    return (
      <React.Fragment>
        {formElements.map((ele, index) => {
          return (
            <div className={`${s['form-item']}`} key={index}>
              <p>{ele.title}:</p>
              {ele.type === 'input' && (
                <Input
                  placeholder={ele.placeholder}
                  value={ele.value}
                  onChange={ele.handler}
                />
              )}
              {ele.type === 'textarea' && (
                <TextArea rows={3} value={ele.value} onChange={ele.handler} />
              )}
              {ele.type === 'editor' && (
                <Editor
                  value={ele.value}
                  onValueChange={ele.handler}
                  highlight={code => highlight(code, languages.sql)}
                  placeholder={ele.placeholder}
                  padding={20}
                  style={{
                    fontFamily: '"Fira code", "Fira Mono", monospace',
                    fontSize: 16,
                    border: '1px solid #ccc',
                    minHeight: '180px',
                    margin: '20px 0',
                  }}
                />
              )}
              {ele.type === 'select' && (
                <Select
                  style={{ minWidth: '250px', maxWidth: '300px' }}
                  dropdownMatchSelectWidth={false}
                  placeholder={ele.placeholder}
                  value={ele.value}
                  onSelect={ele.handler}
                >
                  {ele.options.map((item, j) => (
                    <Option value={item.key} key={j}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              )}
            </div>
          );
        })}
      </React.Fragment>
    );
  };

  render() {
    const {
      isQueryCodeChanged,
      match,
      isQueryProcessing,
      queryFormLoading,
      tableColumns,
      updateRequired,
      type,
    } = this.props;
    return (
      <div className={`ck-card ${s['analytics-query-container']}`}>
        {queryFormLoading && <FullPageLoading fontSize={30} />}
        {!queryFormLoading && (
          <React.Fragment>
            <Row type="flex" justify="space-between">
              <h3>
                {type === 'build-analytics-chart'
                  ? 'Chart Query'
                  : 'Associated Chart'}
              </h3>
              {/* <div>
                <span className={`${s['margin']}`}>Is Custom?</span>
                <Switch
                  checked={queryIsCustom}
                  onChange={this.handleSwitchChange}
                />
              </div> */}
            </Row>
            {this.renderForm()}
            {type === 'build-analytics-chart' && tableColumns.length > 0 && (
              <div>
                <p>Table Columns: </p>
                {tableColumns.map((item, j) => (
                  <Row gutter={[8, 12]} key={j}>
                    <Col span={12}>
                      <Input value={item.name} disabled />
                    </Col>
                    <Col span={12}>
                      <Select
                        style={{ width: '100%' }}
                        dropdownMatchSelectWidth={false}
                        placeholder="Datatype"
                        onSelect={this.handleColumnChange(j, 'dataType')}
                        value={item.dataType}
                      >
                        {this.dataTypeOptions.map((type, i) => (
                          <Option value={type.value} key={i}>
                            {type.name}
                          </Option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                ))}
              </div>
            )}
            {type === 'build-analytics-chart' &&
              match.params &&
              match.params.id &&
              updateRequired && (
                <div className={`${s['note-row']}`}>
                  <span>
                    <Icon type="info-circle" className={`${s['icon']}`} />
                    Columns need to be updated. Please click "Update Query"
                    after verifying data-type.
                  </span>
                </div>
              )}
            <div className={`${s['action-row']}`}>
              {/* {queryIsCustom ? (
                <div>
                  <span className={`${s['margin']}`}>Select Hub:</span>
                  <Select
                    style={{ minWidth: '250px', maxWidth: '300px' }}
                    placeholder="select hub for custom query"
                    dropdownMatchSelectWidth={false}
                    onChange={this.handleHubSelection}
                    value={selectedHub}
                    disabled={!hubs || (hubs && hubs.length === 0)}
                  >
                    {this.renderOptions(hubs)}
                  </Select>
                </div>
              ) : (
              )} */}
              <div />
              <div>
                {isQueryCodeChanged && (
                  <Popconfirm
                    placement="bottom"
                    title="Are you sure you want to discard changes."
                    okText="Yes"
                    cancelText="No"
                    onConfirm={this.handleDiscard}
                  >
                    <Button type="danger" className={`${s['margin']}`}>
                      Discard
                    </Button>
                  </Popconfirm>
                )}
                {match.params && match.params.id ? (
                  <Popconfirm
                    placement="bottom"
                    title="Are you sure you want to update?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={
                      type === 'build-analytics-chart'
                        ? this.handleSave
                        : this.handleAssociationSave
                    }
                    disabled={isQueryProcessing}
                  >
                    <Button type="primary">Update Query</Button>
                  </Popconfirm>
                ) : (
                  <Button
                    type="primary"
                    onClick={
                      type === 'build-analytics-chart'
                        ? this.handleSave
                        : this.handleAssociationSave
                    }
                    disabled={isQueryProcessing}
                  >
                    Save Query
                  </Button>
                )}
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ clusterHubs, analyticsQuery }) => {
  const { hubs } = clusterHubs;
  const {
    queryTitle,
    queryCode,
    isQueryCodeChanged,
    queryIsCustom,
    isQueryProcessing,
    queryFormLoading,
    selectedHub,
    queryTableName,
    queryDb,
    tableColumns,
    updateRequired,
    reportTable,
    reportId,
    chartType,
    chartQuery,
    chartTag,
    chartIdentifier,
    indexCode,
    relatedReportId,
    changeMessage,
    frequency,
  } = analyticsQuery;
  return {
    hubs,
    queryTitle,
    queryCode,
    isQueryCodeChanged,
    queryIsCustom,
    isQueryProcessing,
    queryFormLoading,
    selectedHub,
    queryTableName,
    tableColumns,
    updateRequired,
    reportTable,
    reportId,
    chartType,
    chartQuery,
    chartTag,
    chartIdentifier,
    indexCode,
    relatedReportId,
    changeMessage,
    queryDb,
    frequency,
  };
};

const mapDispatchToProps = {
  saveAnalyticsQuery,
  popupAlert,
  setQueryTitle,
  setQueryCode,
  setIsQueryCodeChanged,
  setQueryIsCustom,
  updateAnalyticsQuery,
  setSelectedQueryHub,
  setQueryTableName,
  setQueryDb,
  setTableColumns,
  fetchReportTables,
  updateAssociatedChart,
  saveAssociatedChart,
  setAssociatedReportId,
  setAssociatedChartType,
  setAssociatedChartTag,
  setAssociatedChartQuery,
  setAssociatedChartIdentifier,
  setIndexCode,
  setRelatedReportId,
  setChangeMessage,
  setQueryFrequency,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AnalyticsQueryForm)
);
